@use "../base/colors";

#project-progress-tracker {
  .header-section {
    margin-top: 1rem;
    .header {
      display: inline-block;
    }
  }
  .date-section {
    margin-left: 1rem;
  }
  .response-forecast-section {
    .bar-chart-container {
      position: relative;
      .axis-label {
        position: absolute;
        top: 95%;
        left: 50%;
        transform: translate(-50%, -95%);
        color: #000000DE;
      }
    }
    .axis-note {
      margin-top: 1.5rem;
      text-align: center;
    }
  }
  .filters-section {
    .filter-by-category {
      thead th, tbody tr {
        border-bottom: none !important;
      }
      tr td {
        border-top: none !important;
      }
      .total {
        font-weight: bolder;
      }
      .reset-filters {
        color: colors.$default;
        text-decoration: underline;
        cursor: pointer;
      }
      .category {
        padding-bottom: 0 !important;
        .sub-title {
          color: colors.$default;
          font-style: italic;
        }
      }
      .facet {
        .bar-chart, .stats {
          vertical-align: middle;
          display: inline-block;
          margin-right: 1rem;
        }
      }
      @media only screen and (max-width: 760px) {
        tr {
          padding-bottom: 0;
          box-shadow: none !important;
        }
      }
    }
    .expert-filters {
      position: sticky;
      top: 0;
      .footer-text {
        margin-bottom: 1rem;
        .total {
          display: inline;
          color: colors.$primary;
          font-weight: bold;
        }      
      }
    }
  }
}