@use "../base/colors";

.project-form {
  padding: 2.5rem;
  .project-title {
    font-size: 18px;
    color: colors.$black;
  }
  .note {
    display: inline;
    float: right;
  }
  .dropdown {
    margin-top: 1rem;
  }
  .view-qualification-questions.container {
    max-height: 300px;
    max-width: 90%; 
    overflow-Y: scroll;
    margin-left:0.5rem !important;
    margin-bottom: 1rem;
  }
  .view-answer-choices {
    margin: 1rem 0;
    max-width: 80%;
    .short-answer {
      input {
        cursor: default
      }
    }
    .answer-choices.row {
      margin-top: 0 !important
    }
    label {
      margin: 0rem 0 0.5rem 0  !important;
      color: colors.$default;
      font-size: 14px !important;
      cursor: default !important
    }
  }
  label {
    font-size: 16px !important;
    margin-top: 2rem !important;
    margin-bottom: 1rem !important;
  }
  .sub-label {
    margin-bottom: 0.5rem;
  }
  .upcoming-stats {
    margin-left: 2rem;
  }

  .completed-stats {
    margin-left: 1.8rem;
  }

  .next {
    margin-top: 1.5rem !important;
    text-align: right !important;
  }

  .basic-btn {
    margin-top: 1rem;
  }
  #radio-buttons td{
    width: 100%;
  }
  #radio-buttons label {
    margin-top: 0 !important;
  }

  #radio-buttons .dropdown {
    margin-top: 0
  }

  .accordion {
    .title {
      font-size: 18px !important;
      font-weight: bold;
      color: colors.$black !important;
    }
    .accordion-content {
      margin: 1rem 2rem 3rem 2rem;
      .current-availability {
        margin-bottom: 2rem;
        .span-link {
          text-decoration: underline;
        }
      }
      .additional-setting {
        margin-bottom: 3rem;
        .header {
          margin-bottom: 0.5rem;
        }
        .scheduling-option {
          .radio {
            display: inline;
          }
          .label {
            color: colors.$black !important;
            display: inline-block;
            .text {
              margin-left: 1.5rem;
            }
          }
        }
        input[type='number'] {
          width: 60px;
        }
        .dropdown {
          min-width: 0;
        }
        .available-days-option {
          display: flex;
          align-items: flex-start;
          .option-label {
            margin-left: 1rem;
            margin-top: 1.5rem;
            .input {
              width: 70px;
            }            
          }
        }
        .date-range-option {
          .radio {
            label {
              color: colors.$default;
              font-size: 14px !important;
            }            
          }
        }
        .react-datepicker__input-container {
          input {
            width: 225px !important;
          }
        }
      }
      .last-additional-setting {
        margin-bottom: 1.5rem;
      }
    }
  }

  #project-keyword-form {
    .sample-keywords {
      .header {
        display: inline;
        margin-right: 2.5rem;
      }
      padding: 1rem !important;
      display: inline-block;
      min-height: fit-content;
    }
  }
}
