@use "../base/colors";
@use "../layout/expertListButton";
@use "../elements/profilePic";

#profile-modal {
  height: 90vh;
  overflow-y: auto;
  .heading {
    .left {
      @extend %profile-info-row-format;
    }
    .right {
      padding-top: 0.5rem;
      .grid {
        float: right !important;
        .row {
          text-align: right !important;
        }
      }
    }
    @extend %expert-list-button;
  }

  .content {
    .detail {
      .section {
        max-height: 53vh;
        overflow-y: auto;
      }
    }
    .history {
      border-left: 1px solid colors.$line-color;
      .meeting-list {
        margin-bottom: 1rem;
        .heading {
          border-bottom: 1px solid colors.$line-color;
          .see-all-btn {
            cursor: pointer;
            float: right;
          }
        }
        .content {
          max-height: 50vh;
          overflow-y: auto;
          .item {
            margin-bottom: 1rem;
          }
        }
        .review-link {
          color: colors.$primary;
          cursor: pointer;
        }
        .span-link {
          color: colors.$primary !important;
          text-decoration: underline !important;
          font-weight: bold;
        }
      }
    }
  }

}