$background: #F6F7F7;
$default: #697A8A;
$black: #09183E;
$green: #27AE60;
$primary: #1B5E82;
$line-color: rgba(0, 0, 0, 0.2);
$label-grey: #EEEEEE;
$label-color: #3E8FA9;
$label-background: rgba(62, 143, 169, 0.15);
$credit-label-color: #368A8E;
$credit-label-background: rgb(218, 231, 231);