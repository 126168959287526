#qualtrics-survey-access {
  .invalid-screening-warning {
    p {
      margin-bottom: 0;
    }
    .span-link {
      color: darkred !important;
      text-decoration: underline;
    }
  }
}