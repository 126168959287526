@use "../base/colors";

%itemColor {
  background: colors.$primary !important;
  color: #fff !important;
}
.pagination {
  margin-top: 1rem;
  text-align: center;
  .active.item {
    @extend %itemColor;
  }
}