#search {
  padding: 2.5rem;
  padding-top: 0.5rem;
  .stats {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

#filter-section {
  border: 1px solid transparent;
  border-top: 0;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 1rem !important;

  .heading {
    background: #D7E8E8;
    border-radius: 10px 10px 0 0;
  }

  .categories {
    margin-top: 1rem;
  }
}
