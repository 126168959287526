@use "../base/colors";

#lists {
  padding: 2.5rem;
  #list-name {
    .before-edit {
      .header {
        display: inline;
        margin-right: 0.5rem;
      }
      .icon {
        color: colors.$black;
        cursor: pointer;
      }
    }
  }
  #list-experts {
    .cards {
      margin-top: 0.5rem;
    }
  }
}

#list-share-modal {
  .description {
    margin-top: 0.5rem;
  }
}