@use "../base/colors";

#meeting, #error {
    width: 50%;
    background: #fff;
    border: 1px solid transparent;
    margin-left: 1rem;
    margin-top: 3rem;
    padding: 1rem 3rem 3rem 3rem;}
    #meeting{
    .meeting-datetime {
        grid-area: datetime;
      }
      .meeting-invite {
        grid-area: invite
      }
      .action-btns {
        grid-area: primaryBtns;
        margin-top: auto;
        margin-bottom: auto;
        width: 80%;
        Button {
            width: 100%
        }
    }
      .secondary-action-btns{
        grid-area: secondaryBtns;
        width:100%;
        margin: auto 0;
        #report-no-show {
            cursor: pointer;
            line-height: 2rem
        }
        #report-no-show.disable-no-show {
            color: lightgrey;
            cursor: not-allowed
        }
        #view-upcoming-meetings {
            color: colors.$default;
        }
      }
      .pro-tip {
        grid-area: protip;
        width: 100%;
        margin: auto auto 0 0; 
        #pro-tip-subheader {
            line-height:15px;
        }
        #pro-tip-calendar-link:before {
            content: "\00a0 "
        }
      }
    .grid-container {
        display: grid;
        width:100%;
        grid-template: 50px 2fr 1fr 2fr / 1fr 2fr;
        grid-template-areas: 
            "datetime invite"
            "primaryBtns invite"
            "secondaryBtns invite"
            "protip invite";
        column-gap: 3rem;
        row-gap: 1%;
    }}
    @media only screen and (max-width: 1199px ){
        #meeting {
            .action-btns {
                width: 100%
            }
        }
    }
    @media only screen and (max-width: 991px) {
        #meeting, #error {
            padding: 1rem;
        }
        #meeting {
            .action-btns{
                width: 80%;
            }
            
            .meeting-invite {
                width: 100%;
                #meeting-invite-list {
                    margin: 2rem auto 0 0;
                    width:100%
                }  
            }
            .grid-container {
                grid-template: 50px 1fr 3fr 100px 1fr / 80vw;
                grid-template-areas:
                    "datetime"
                    "primaryBtns"
                    "invite"
                    "secondaryBtns"
                    "protip"
            }
            .secondary-action-btns{
                width: 100%;
                margin: auto 0;
            }
            .pro-tip {
                width: 100%;
                margin: 0 auto 0 0 
            }
        }
        }
        
