@use "../base/colors";

#project-creation-success-popup {
  .sub {
    margin-top: 1rem !important;
  }
  .span-link {
    font-weight: bold;
    color: colors.$primary;
  }
  .qualtrics-credentials-box {
    min-height: 10rem;
    padding: 2rem;
    .primary-btn {
      margin-left: 1rem;
    }
  }
}