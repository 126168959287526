@use "../base/colors";

%form-modal {
  .form {
    label {
      color: colors.$default !important;
    }
    margin-bottom: 1rem;
  }
  .subtext {
    float: left;
    color: colors.$black;
    margin-bottom: 1rem;
  }
}