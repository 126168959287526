@use "../base/colors";

%stats-block {
  background: #fff;
  border: 1px solid colors.$background;
  padding: 1rem;
  font-size: 16px;
  text-align: center;
  .label {
    margin-bottom: 2.5rem;
  }
  .number {
    font-size: 56px;
    color: colors.$black;
  }
  .empty-spacing {
    padding-bottom: 1.5rem;
  }
  .footer {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
}