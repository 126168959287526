@use "../base/colors";
@use "../elements/spanLink";

#meeting-tab {
  width: 100%;
  background: #fff;
  border: 1px solid transparent;
  margin-left: 1rem;
  padding: 1rem;
  .no-meeting {
    color: colors.$black;
    text-align: center;
    padding-bottom: 5rem;
    button {
      margin-top: 1rem;
    }
  }
  .active.item {
    color: colors.$primary;
    border-color: colors.$primary !important;
  }
  .active.item:hover {
    border-color: colors.$primary !important;
  }
  .meetings {
    color: colors.$black;
    .headline {
      color: colors.$default;
    }
    .item {
      margin-bottom: 1rem;
    }
    .project-link {
      color: colors.$black;
    }
    .span-link {
      @extend %item-link;
    }
  }
}
