@use "../base/normalize";

#welcome {
  p {
    @extend %center;
    font-size: 16px;
  }
}

#introduction {
  .description {
    @extend %center;
  }
}

#allset {
  padding: 5rem !important;
  .category-section {
    max-height: 50vh;
    overflow-y: auto;
    .label {
      margin: 0.5rem;
    }
  }
}
