@use "../base/colors";
@use "../elements/label";

#navbar {
  .nav-link {
    a {
      color: colors.$default;
    }
    .active {
      color: colors.$label-color;
      font-weight: bold;
    }
  }

  .nav-icon {
    padding-bottom: 1rem;
    .settings {
      @extend %icon;
      border-radius: 50%;
      cursor: pointer;
      font-weight: bold;
      color: colors.$primary;
      background-color: rgba(0, 0, 0, 0.2);
      .dropdown.icon {
        display: none !important;
      }
      a {
        color: colors.$default;      
      }
    }   
  }

  .nav-search {
    .suggestion {
      .text {
        font-size: 12px;
      }
    }
  }  
}
