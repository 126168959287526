#availability-view {
  .rbc-calendar {
    padding: 1rem;
    width: 100% !important;
    height: 600px;
  }
  .label {
    width: 100%;
    text-align: center;
  }
}