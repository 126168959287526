@use "../base/colors";

#members {
  .table {
    color: colors.$default !important;
    .actions {
      .icon {
        cursor: pointer;
        margin-right: 2.5rem;
      }
    }
  }
}