@use "../base/colors";

%expert-list-button {
  .button-row {
    padding-bottom: 0 !important;
    width: 80% !important;
  }
  .expert-list-button {
    display: flex;
    width: 100%;
    .dropdown {
      width: 15% !important;
      color: white !important;
      background: colors.$primary !important;
    }
    .primary-btn, .basic-btn {
      width: 85% !important;
    }    
  }
}