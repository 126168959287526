@use "../base/colors";

#projects {
  padding: 2.5rem;
  .project-list {
    .card {
      .status {
        color: colors.$green;
        float: right;
      }
      .Unpublished-status {
        color: red;
      }
      .Completed-status {
        color: colors.$default;
      }
      .meta {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
        .list {
          .item {
            margin-left: 0.5rem;
          }
        }
      }
      .header {
        a {
          color: colors.$black !important;
        }
      }
      .label {
        float: right;
      }
      .complete-stats {
        .progress {
          margin: 0;
          background: #fff;
          .bar {
            background-color: colors.$green !important;
            border-radius: 8px !important;
          }
        }
        .stats {
          margin-top: 0.5rem;
          .icon {
            color: colors.$green;
          }
        }
      }
      .statistics {
        padding-left: 0.5rem;
        padding-bottom: 0.5rem;
        .statistic {
          .label {
            font-weight: normal !important;
            text-transform: none !important;
            font-size: 14px !important;
          }
        }        
      }
      .description {
        margin-bottom: 2rem;
      }
    }
  }
}