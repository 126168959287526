@use "../base/colors";

#meeting-modal {
  .list {
    .item {
      margin-bottom: 1rem !important;
      .content {
        .description {
          color: colors.$default !important;
        }
        .credit-record {
          padding: 1.5rem !important;
        }
      }
      .input {
        float: right !important;
      }
      .table {
        padding: 1.5rem;
        th, tbody {
          color: colors.$default !important;
        }
      }
    } 
  }
}