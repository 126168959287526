@use "../base/colors";

.primary-btn {
  color: #fff !important;
  background: colors.$primary !important;
}

.basic-btn {
  color: colors.$primary !important;
  border: 1px solid colors.$primary !important;
  background: #fff !important;
}

.remove-icon {
  cursor: pointer;
}

.disabled-btn {
  cursor: default !important;
  opacity: 0.45;
}

.link-btn {
  background: none !important;
  padding: 0 !important;
  color: colors.$black !important;
  &:hover {
    color: colors.$default !important;
  }
}
