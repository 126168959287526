#availability {
  padding-top: 2.5rem !important;
  .dash {
    padding-top: 0.5rem;
  }
  .weeklyhours {
    margin-top: 2.5rem;
    button {
      padding: 1rem !important;
    }
  }
}