@use "../base/colors";

%position {
  float: left;
  margin-right: 1rem;
  position: relative; 
}
%avatar {
  width: 85px !important;
  height: 85px !important;
}
%flag {
  width: 26px;
  height: 26px;
  position: absolute;
}

.expert-profile-pic {
  @extend %position;
  .expert-avatar {
    @extend %avatar;
    border-radius: 10px;
  }
  .country-flag {
    @extend %flag;
    top: 67px;
    left: 70px;
  }
}

%profile-info-row-format {
  .expert-profile-pic {
    @extend %position;
    .expert-avatar {
      @extend %avatar;
      border-radius: 50px !important;
    }
    .country-flag {
      @extend %flag;
      top: 65px !important;
      left: 60px !important;
    }
    margin-right: 1.5rem;    
  }
  .header {
    margin-top: 0 !important;
    .sub.header {
      color: colors.$default !important;
      font-size: 14px !important;
    }
    .credit-label {
      background: transparent;
      padding-left: 0 !important;
    }  
  }
}