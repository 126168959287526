@use "../base/colors";

.filter-category {
  width: 100%;
  background: #fff;
  margin-top: 1rem !important;

  .category-name {
    .header {
      font-size: 16px !important;
      i {
        float: right !important;
      }      
    }
    margin-bottom: 1rem;
  }

  .category-content {
    .label, .icon {
      color: colors.$default !important;
      background: colors.$label-grey !important;
    }

    .category-options {
      max-height: 15vh;
      overflow-y: auto;
    }
  }
}