@use "../base/colors";

%icon {
  border: 1px solid transparent;
  padding: 0.7rem 1rem 0.7rem 1rem !important;
  font-size: 14px !important;
}

.search-label {
  background-color: colors.$label-background !important;
  color: colors.$label-color !important;
  text-decoration: underline;
}