@use "../base/colors";

#request-modal {
  .header {
    .meta {
      float: right;
      font-size: small;
    }
  }
  .content {
    .span-link {
      .icon {
        margin-top: 1rem;
        margin-right: 0.8rem;
      }
    }
    .meta {
      color: colors.$black;
      font-size: small;
      font-weight: bold;
    }
    margin-bottom: 2.5rem;
  }
  .actions {
    .tos {
      margin-top: 0.8rem;
      margin-left: 0.5rem;
      float: left;
    }
  }
}