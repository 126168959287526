@use "colors";

$mobile-size: 768px;

body {
  color: colors.$default !important;
  background-color: colors.$background !important;
}

.main {
  min-height: 100vh;
}

.header {
  color: colors.$black !important;
}

.ui.negative.message {
  .header {
    color: #912d2b !important;
  }
}

.title {
  font-size: 32px !important;
}

.menu {
  .active.item {
    color: colors.$primary !important;
    border-color: colors.$primary !important;
  }
  .active.item:hover {
    border-color: colors.$primary !important;
  }  
}

.text-description {
  white-space: pre-wrap !important;
}

%center {
  margin: auto !important;
  width: 50% !important;
}