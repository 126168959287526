@use "../base/colors";

%section {
  margin-top: 2.5rem;
  .icon {
    color: colors.$primary;
  }
  .copy-section {
    margin-left: 3rem;
    .form-input, .input > input {
      background: colors.$label-grey !important;
      border: 0.5px solid colors.$primary !important;
      border-radius: 5px;
      color: colors.$black;
    }
  }
}

#project-share-modal {
  .form-label {
    font-weight: bold;
    ::after {
      content: "\a";
      white-space: pre;
    }
  }
  .project-link-section {
    @extend %section;
  }
  .message-template-section {
    @extend %section;
    .copy-section {
      .form-input {
        margin-top: 0;
        white-space: pre-wrap;
      }
      .copy-btn {
        margin-top: 1rem;
        text-align: right !important;
      } 
    }
  }
}