@use "../base/colors";
@use "../layout/statsBlock";

.profile-stats {
  .stats-item {
    @extend %stats-block;
  }
  .credit-link {
    a {
      color: colors.$black;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}