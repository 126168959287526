@use "../layout/statsBlock";

#credits {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  .stats-item {
    @extend %stats-block;
  }
  .history-header {
    padding-bottom: 0;
    .header {
      display: inline;
    }
    .export-btn {
      margin-left: 1rem;
    }
  }
  .history {
    padding-top: 1rem;
    .segment {
      width: 100% !important;
      .list {
        .item {
          margin-bottom: 1rem;
        }
      }
      .pages {
        margin-top: 2.5rem !important;
        text-align: center;
      }
    }
  }
}