@use "../base/colors";

%item-link {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.span-link {
  cursor: pointer;
  color: colors.$black;
}