@use "../base/colors";
@use "../base/normalize";
@use "../layout/expertListButton";

.expert-card {
  .pending-invitations {
    background: colors.$black !important;
    color: #fff;
    opacity: 80%;
  }
  .credit-label {
    padding: 0.5rem !important;
    margin-right: 0.5rem;
    font-size: 12px !important;
  }
  .content {
    max-height: 50vh;
  }
  .description {
    padding: 1rem;
  }
  @extend %expert-list-button;
  @media screen and (max-width: normalize.$mobile-size) {
    .pending-invitations {
      position: relative !important;
    }
    .ui.top.attached.label+[class*="right floated"]+*,
    .ui.top.attached.label:first-child+:not(.attached) {
      margin-top: 0 !important;
    }
  }
}