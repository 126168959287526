@use "../layout/formModal";

#review-modal {
  .icon {
    font-size: 24px;
  }
  .container {
    margin-bottom: 1.5rem;
  }
  @extend %form-modal;
}