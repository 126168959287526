@use "../base/colors";

#landing-container{
  background-color: #f2f8fe;
  width:100vw;
  height:100vh;
}
#landing {
  .top-link {
    margin: 1rem 0 10rem 65rem !important;
    color: colors.$black !important;
    span {
      color: colors.$green;
      font-size: 14px;
    }
  }
  .segment {
    background-color: #f2f8fe !important;
    border: none !important;
    img {
      margin-left: 2rem;
    }
    padding: 0 !important;
    margin: 0 !important;
  }
  .side-image {
    background-color: #f2f8fe;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 100%;
    height: 80vh !important;
    padding-left: 5rem;
    button {
      width: 25%;
    }
    .heading {
      margin-top: 5rem;
      font-size: 48px !important;
    }
    .footer-text {
      margin-top: 1rem;
      color: colors.$default !important;
    }
    .sub-footer-text {
      color: colors.$default !important;
      margin-top: 0.5rem;
      padding-right: 60% !important;      
    }
    .list {
      margin-top: 3.5rem;
      .icon {
        color: colors.$green !important;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .top-link {
      margin: 0 !important;
    }
    .segment {
      width: 100%;
      img {
        width: 250px;
      }
    }
    .side-image {
      background-image: none !important;
      height: 100vh !important;
      padding: 0 !important;
      .heading {
        margin: 0 !important;
        font-size: 24px !important;
      }
      button {
        width: 100%;
      }
      .sub-footer-text {
        padding-right: 0 !important;
      }
    }
  }
}