@use "../base/colors";

#qualification-questions {
  .sub {
    color: colors.$black !important;
    margin: 1rem !important;
    font-size: 14px !important;
  }
  .sample {
    text-decoration: underline;
    float: right;
    font-size: 12px;
    color: colors.$black;
  }
  .add-more {
    margin: 1rem;
    text-align: center;
  }
  .remove-question-btn {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    color: colors.$default;
    cursor: pointer;
  }
  .elaborate-btn {
    label {
      padding-left: 2rem;
      margin-top: 1rem !important;
      font-size: 12px !important;
    }
  }
  .qualification-question-header {
    font-size: 1.2rem !important;
    color: colors.$black !important
  }
  .qualification-question-subheader {
    color: colors.$black !important;
    vertical-align: bottom;
    padding-bottom: 1rem
  }

 .answer-choices{
  margin-top: 0;
  padding: 0 0 0 2.3rem !important;
  padding-bottom: 0.5rem !important;
  
  .field {
    margin: 0
  }
  label {
    margin: 0 !important;
    color: colors.$default;
    font-size: 14px !important;
    cursor: default !important;
  }
  
}
.answer-choices.short-answer, .view-answer-choices.short-answer {
  input {
    cursor: default !important
  }
}
.answer-choice-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 100%;
}
.answer-choices .answer-choice-field {
  width: 15px !important
}

.answer-choices.other-included {
  margin-top: 1.5rem;
  label {
    cursor: pointer !important
  }
}
.answer-choice-btn{
  cursor: pointer;}
.answer-choice-btn:hover{
  color: colors.$primary;
}
}