.centerized-layout {
  padding-top: 2.5rem !important;
  .segment {
    padding: 2.5rem !important;
    width: 100%;
  }
  .heading {
    margin-bottom: 1.5rem !important;
  }
}
