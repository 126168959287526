#edit-project {
  .actions {
    margin-top: 2rem;
    .buttons {
      text-align: right;
      .basic-btn {
        margin-right: 1rem;
      }
    }
  }
}