@use "../base/colors";
@use "../elements/label";

.credit-label {
  @extend %icon;
  border-radius: 25px;
  background-color: colors.$credit-label-background;
  color: colors.$credit-label-color;
  img {
    position: relative !important;
    vertical-align: middle !important;
    margin-right: 0.5rem !important;
  }
}