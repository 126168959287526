@use "../base/colors";

#external-accounts {
  .segment {
    background-color: colors.$background !important;
  }
  .small-box {
    padding: 1.5rem !important;
  }
  .white-box {
    background-color: white !important;
    outline: none;
  }
  .connection {
    border-left: none;
    border-right: none;
    box-shadow: none !important;
    margin-bottom: 1.5rem;
  }
  .indented-alert {
    font-size: medium;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 30px;
  }
}